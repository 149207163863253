<template>
  <div class="gzdt">
    <nav-bar title="工作动态"></nav-bar>
    <ul>
      <li v-for="(item, index) in arr" :key="index">
        <h2>{{ item.title }}</h2>
        <img :src="item.image" alt="" />
      </li>
    </ul>
  </div>
</template>
<script>
import NavBar from "@/components/content/navBar/NavBar.vue";
export default {
  data() {
    return {
      arr: [
        {
          title:
            "2019年7月23日背着法徽前往东坑某村就吴某滥伐林木罪刑事附带明示公益诉讼案件公开开庭审理",
          image: require("@/assets/img/gzdt/list1.jpg"),
        },
        {
          title:
            "2019年12月19日在景宁县廊桥巡回审判庭公开开庭审理吴某非法捕捞水产品案件，该案系引发民众强烈关注鹤溪河毒鱼案",
          image: require("@/assets/img/gzdt/list2.jpg"),
        },
        {
          title:
            "2019年发布景宁县环境资源审判工作白皮书，对本辖区环境资源类案件进行调查研究，并发布5个典型案例",
          image: require("@/assets/img/gzdt/list3.jpg"),
        },
        {
          title:
            "2020年4月24日 景宁县法院县检察院共同发布涉环资（野生动植物保护）型案例",
          image: require("@/assets/img/gzdt/list4.jpg"),
        },
        {
          title:
            "2020年7月1日在县美丽景宁建设领导小组”五水共治“（河长制）办公室挂牌成立”驻县河长制办公室巡回审判点“并进行揭牌",
          image: require("@/assets/img/gzdt/list5.jpg"),
        },
        {
          title:
            "2020年世界环境日前往畲寨东弄村开展普法宣传活动，向当地群众宣传野生动植物保护以及安全用火等知识",
          image: require("@/assets/img/gzdt/list6.jpg"),
        },
        {
          title: "景宁法院发布全国首份“生态失信行为人名录” 23人上榜",
          image: require("@/assets/img/gzdt/list7.jpg"),
        },
      ],
    };
  },
  components: {
    NavBar,
  },
};
</script>
<style scoped>
.gzdt {
  padding: 0.1rem 0.3rem;
  padding-top: 0.98rem;

  box-sizing: border-box;
}
li {
  border-bottom: 1px solid #999;
  padding-bottom: 0.2rem;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
}
h2 {
  font-size: 0.3rem;
  line-height: 1.2;
}
img {
  width: 100%;
  height: 3.3rem;
  margin-top: 0.15rem;
}
</style>